var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "dn-help dn-container" },
    [
      _c("back-button", {
        on: {
          back: function ($event) {
            return _vm.$router.push("/")
          },
        },
      }),
      _c("h1", { staticClass: "dn-help__title" }, [_vm._v("Drumnow Support")]),
      _c("hr"),
      _c(
        "section",
        { staticClass: "mt-10" },
        [
          _c("v-btn", { on: { click: _vm.handleFeedback } }, [
            _vm._v("General Feedback / Bug Report?"),
          ]),
          _vm.feedbackActive
            ? _c(
                "div",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-4 mt-8",
                      attrs: { "max-width": "700px" },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-form",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.selectItems,
                                      name: "subject",
                                      label: "Subject",
                                    },
                                    model: {
                                      value: _vm.subject,
                                      callback: function ($$v) {
                                        _vm.subject = $$v
                                      },
                                      expression: "subject",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      name: "email",
                                      label: "Email Address",
                                      id: "email",
                                      type: "email",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Message",
                                      name: "name",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.message,
                                      callback: function ($$v) {
                                        _vm.message = $$v
                                      },
                                      expression: "message",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label:
                                        "Should we contact you about this?",
                                    },
                                    model: {
                                      value: _vm.followUp,
                                      callback: function ($$v) {
                                        _vm.followUp = $$v
                                      },
                                      expression: "followUp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          !_vm.loading
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    disabled: _vm.disabled,
                                  },
                                  on: { click: _vm.submitForm },
                                },
                                [_vm._v("Submit")]
                              )
                            : _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "mt-10", staticStyle: { "max-width": "1000px" } },
        [
          _c("h1", { staticClass: "dn-help__title--sm" }, [_vm._v("FAQ")]),
          _c(
            "v-expansion-panels",
            { staticClass: "mt-10" },
            _vm._l(_vm.faq, function (item, i) {
              return _c(
                "v-expansion-panel",
                { key: i },
                [
                  _c("v-expansion-panel-header", [
                    _vm._v(_vm._s(item.question)),
                  ]),
                  _c("v-expansion-panel-content", [
                    _vm._v(_vm._s(item.answer)),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("section", [
        _c("h1", { staticClass: "dn-help__title--sm" }, [_vm._v("Links")]),
        _c(
          "ul",
          { staticClass: "dn-help__list" },
          _vm._l(_vm.externalLinks, function (link) {
            return _c(
              "li",
              { key: link.title, staticClass: "dn-help__list-item" },
              [
                _c(
                  "a",
                  {
                    staticClass: "dn-help__link",
                    attrs: { href: link.url, target: "_blank" },
                  },
                  [_vm._v(_vm._s(link.title))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }